var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CLink",
    {
      staticClass: "c-message",
      class: { "c-message-read": _vm.email.is_read },
      attrs: {
        to: {
          name: "Message",
          params: { id: _vm.messageID, folder: _vm.folder, label: _vm.label },
          query: { user: _vm.email.user_id },
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-column align-items-center mr-3",
          staticStyle: { width: "60px" },
        },
        [
          _c("CImg", {
            staticClass: "c-avatar-img",
            staticStyle: { width: "40px", height: "40px" },
            attrs: {
              src: _vm.email.sender?.image_icon || _vm.email.sender?.gravatar,
              placeholderColor: "lightgray",
            },
          }),
          _vm.email.is_my_task
            ? _c(
                "CBadge",
                { staticClass: "mt-2 badge-task" },
                [
                  _c("CIcon", { attrs: { name: "cil-bolt" } }),
                  _vm._v(" Your Task"),
                ],
                1
              )
            : _vm._e(),
          _c(
            "CBadge",
            { staticClass: "my-2", attrs: { color: _vm.emailLabel.color } },
            [_vm._v(_vm._s(_vm.emailLabel.label))]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "c-tooltip",
                  rawName: "v-c-tooltip",
                  value: {
                    content: _vm.email.is_starred ? "Starred" : "Not starred",
                  },
                  expression:
                    "{\n        content: email.is_starred ? 'Starred' : 'Not starred',\n      }",
                },
              ],
              on: { click: (e) => _vm.toggleStarred(e) },
            },
            [
              _c("CIcon", {
                class: { "text-warning": _vm.email.is_starred },
                attrs: { name: "cil-star", height: "24px" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "c-message-details w-100" }, [
        _c("div", { staticClass: "c-message-headers" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "c-message-headers-subject mb-2" },
              [
                _c(
                  "span",
                  {
                    staticClass: "mr-1",
                    class: { "font-weight-bold": !_vm.email.is_read },
                  },
                  [_vm._v(_vm._s(_vm.email.subject))]
                ),
                _vm.email.is_outgoing
                  ? _c(
                      "CBadge",
                      { staticClass: "mr-1", attrs: { color: "light" } },
                      [
                        _c(
                          "span",
                          { staticClass: "text-info" },
                          [
                            _c("CIcon", { attrs: { name: "cil-send" } }),
                            _vm._v(" Outgoing Email"),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.email.is_trashed
                  ? _c("CBadge", { attrs: { color: "light" } }, [
                      _c(
                        "span",
                        { staticClass: "text-danger" },
                        [
                          _c("CIcon", { attrs: { name: "cil-trash" } }),
                          _vm._v(" Trashed"),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "c-message-headers-from" }, [
              _vm.email.is_outgoing
                ? _c(
                    "div",
                    [
                      _c("CBadge", { staticClass: "badge-msg-from" }, [
                        _vm._v("TO"),
                      ]),
                      _c("span", { staticClass: "ml-1 text-muted" }, [
                        _vm._v(
                          "<" + _vm._s(_vm.email.reply_to?.toString()) + ">"
                        ),
                      ]),
                    ],
                    1
                  )
                : _c("div", [
                    _c(
                      "span",
                      { class: { "font-weight-bold": !_vm.email.is_read } },
                      [_vm._v(_vm._s(_vm.email.sender?.first_name))]
                    ),
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v("<" + _vm._s(_vm.email.from) + ">"),
                    ]),
                  ]),
            ]),
            _vm.email.cc && _vm.email.cc.length > 0
              ? _c(
                  "div",
                  { staticClass: "c-message-headers-from" },
                  [
                    _c("CBadge", { staticClass: "badge-msg-from" }, [
                      _vm._v("CC"),
                    ]),
                    _c("span", { staticClass: "text-muted ml-1" }, [
                      _vm._v(_vm._s(_vm.email.cc.join(", "))),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.email.restaurant
              ? _c(
                  "div",
                  { staticClass: "c-message-headers-from" },
                  [
                    _c("CIcon", { attrs: { name: "cil-restaurant" } }),
                    _c("span", { staticClass: "text-muted ml-1" }, [
                      _vm._v(_vm._s(_vm.email.restaurant.restaurant_name)),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "c-message-headers-date" }, [
            _vm._v(
              " " +
                _vm._s(_vm.moment_created_at.calendar()) +
                " (" +
                _vm._s(_vm.moment_created_at.fromNow()) +
                ") "
            ),
          ]),
        ]),
        _c("div", {
          staticClass: "c-message-body text-medium-emphasis small mt-2",
          domProps: { innerHTML: _vm._s(_vm.email.plain_reply) },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }