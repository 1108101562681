var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.routeName == "Trash" && _vm.emails.length > 0
        ? _c(
            "div",
            { staticClass: "et" },
            [
              _vm._v(
                " You have " +
                  _vm._s(_vm.emails.length) +
                  " conversations in Trash. "
              ),
              _c(
                "CButton",
                {
                  attrs: { color: "light", size: "sm", variant: "ghost" },
                  on: { click: _vm.emptyTrash },
                },
                [_vm._v(" Empty Trash now ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "ul",
        { staticClass: "c-messages p-0 mb-0" },
        [
          _vm._l(_vm.emails, function (email, index) {
            return [
              _c(
                "InboxMessage",
                _vm._b(
                  {},
                  "InboxMessage",
                  {
                    email,
                    folder: _vm.routeName.toLowerCase(),
                    label: _vm.label,
                  },
                  false
                )
              ),
            ]
          }),
        ],
        2
      ),
      !_vm.loading && _vm.emails.length == 0
        ? _c("p", {
            staticClass: "mb-0",
            domProps: {
              innerHTML: _vm._s(_vm.emptyMessages[_vm.routeName.toLowerCase()]),
            },
          })
        : _vm._e(),
      _c(
        "CElementCover",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { opacity: 0.4 },
        },
        [
          _c("CSpinner", {
            attrs: { size: "5xl", grow: "", color: "primary" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }